@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Thin.eot");
  src: url("fonts/Aeonik-Thin.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-Thin.woff2") format("woff2"),
    url("fonts/Aeonik-Thin.woff") format("woff"),
    url("fonts/Aeonik-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-RegularItalic.eot");
  src: url("fonts/Aeonik-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-RegularItalic.woff2") format("woff2"),
    url("fonts/Aeonik-RegularItalic.woff") format("woff"),
    url("fonts/Aeonik-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-ThinItalic.eot");
  src: url("fonts/Aeonik-ThinItalic.eot?#iefix") format("embedded-opentype"),
    x url("fonts/Aeonik-ThinItalic.woff2") format("woff2"),
    url("fonts/Aeonik-ThinItalic.woff") format("woff"),
    url("fonts/Aeonik-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-MediumItalic.eot");
  src: url("fonts/Aeonik-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-MediumItalic.woff2") format("woff2"),
    url("fonts/Aeonik-MediumItalic.woff") format("woff"),
    url("fonts/Aeonik-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Medium.eot");
  src: url("fonts/Aeonik-Medium.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-Medium.woff2") format("woff2"),
    url("fonts/Aeonik-Medium.woff") format("woff"),
    url("fonts/Aeonik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Regular.eot");
  src: url("fonts/Aeonik-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-Regular.woff2") format("woff2"),
    url("fonts/Aeonik-Regular.woff") format("woff"),
    url("fonts/Aeonik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-LightItalic.eot");
  src: url("fonts/Aeonik-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-LightItalic.woff2") format("woff2"),
    url("fonts/Aeonik-LightItalic.woff") format("woff"),
    url("fonts/Aeonik-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Light.eot");
  src: url("fonts/Aeonik-Light.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-Light.woff2") format("woff2"),
    url("fonts/Aeonik-Light.woff") format("woff"),
    url("fonts/Aeonik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-BoldItalic.eot");
  src: url("fonts/Aeonik-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-BoldItalic.woff2") format("woff2"),
    url("fonts/Aeonik-BoldItalic.woff") format("woff"),
    url("fonts/Aeonik-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Black.eot");
  src: url("fonts/Aeonik-Black.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-Black.woff2") format("woff2"),
    url("fonts/Aeonik-Black.woff") format("woff"),
    url("fonts/Aeonik-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-BlackItalic.eot");
  src: url("fonts/Aeonik-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-BlackItalic.woff2") format("woff2"),
    url("fonts/Aeonik-BlackItalic.woff") format("woff"),
    url("fonts/Aeonik-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Bold.eot");
  src: url("fonts/Aeonik-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-Bold.woff2") format("woff2"),
    url("fonts/Aeonik-Bold.woff") format("woff"),
    url("fonts/Aeonik-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Air";
  src: url("fonts/Aeonik-Air.eot");
  src: url("fonts/Aeonik-Air.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-Air.woff2") format("woff2"),
    url("fonts/Aeonik-Air.woff") format("woff"),
    url("fonts/Aeonik-Air.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Air";
  src: url("fonts/Aeonik-AirItalic.eot");
  src: url("fonts/Aeonik-AirItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Aeonik-AirItalic.woff2") format("woff2"),
    url("fonts/Aeonik-AirItalic.woff") format("woff"),
    url("fonts/Aeonik-AirItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  font-family: Aeonik, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
